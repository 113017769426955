<template>
  <v-app>
    <gavcom-core-app-bar />

    <gavcom-core-drawer />

    <gavcom-core-admin-drawer />

    <gavcom-core-view />

    <!-- <gavcom-core-settings /> -->

    <base-loader />
    <base-form-change-branch-office />
    <!-- <base-form-lock /> -->
  </v-app>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import { mapGetters } from 'vuex'

export default {
  mixins: [MainExternalData],

  name: "GavcomIndex",

  components: {
    GavcomCoreAppBar: () => import("@/layouts/gavcom/core/AppBar"),
    GavcomCoreDrawer: () => import("@/layouts/gavcom/core/Drawer"),
    GavcomCoreAdminDrawer: () => import("@/layouts/gavcom/core/AdminDrawer"),
    //GavcomCoreSettings: () => import('@/layouts/gavcom/core/Settings'),
    GavcomCoreView: () => import("@/layouts/gavcom/core/View"),
  },

  data: () => ({
    expandOnHover: false,
  }),

  computed: {
    ...mapGetters(['activeSession'])
  },

  created() {
    if (this.userData.passwordExpired) {
      this.$router.push({ name: 'ChangePassword' })
    }
  },
}
</script>

<style>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.centered-input input {
  text-align: center;
}

.uppercase-input input {
  text-transform: uppercase;
}

.custom-max-width{
	max-width: 120px;
}

.custom-data-table-header{
	font-weight: 700 !important;
  font-size: 14px !important;
}
</style>
